export const HomeHandler = {

    DELTA: 10,
    featureAside: null,
    descriptionRow: null,
    projectAside: null,
    videoGroup: null,

    init(){
        HomeHandler.featureAside = document.querySelector('.feature-aside');
        HomeHandler.descriptionRow = document.querySelector('.description-row');
        HomeHandler.projectAside = document.querySelector('.project-aside');
        HomeHandler.videoGroup = document.querySelector('.video-group');
        HomeHandler.addEventListeners();
    },

    addEventListeners(){
        window.addEventListener('load', HomeHandler.manageTitles);
        window.addEventListener('resize', HomeHandler.manageTitles);
    },

    manageTitles(){
        HomeHandler.featureAside.style.width = HomeHandler.descriptionRow.offsetHeight + HomeHandler.DELTA + 'px';
        HomeHandler.featureAside.style.left = -HomeHandler.descriptionRow.offsetHeight - HomeHandler.DELTA + 'px';
        HomeHandler.projectAside.style.width = HomeHandler.videoGroup.offsetHeight + HomeHandler.DELTA + 'px';
        HomeHandler.projectAside.style.left = -HomeHandler.videoGroup.offsetHeight - HomeHandler.DELTA + 'px';
    }
};
